/* eslint-disable jsx-a11y/html-has-lang */

"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { InternalErrorPageLayout } from "@/src/components/errorScreens/InternalErrorPageLayout";
import { AntdRegistry } from "@ant-design/nextjs-registry";
import useAppContext from "@/src/components/appContext/useAppContext";
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  const {
    ctx
  } = useAppContext();
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error, {
      user: {
        email: ctx.user?.email,
        id: ctx.user?.supplierId,
        username: ctx.user?.name
      }
    });
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <AntdRegistry data-sentry-element="AntdRegistry" data-sentry-source-file="global-error.tsx">
          <InternalErrorPageLayout title="Unexpected error" description="The server encountered an unexpected error, please try again later" data-sentry-element="InternalErrorPageLayout" data-sentry-source-file="global-error.tsx" />
        </AntdRegistry>
      </body>
    </html>;
}